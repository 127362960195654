import Price from "@api/types/price";

export function formatMoney(price: Price | number): string {
  if (isPrice(price)) {
    return Intl.NumberFormat("en-US", {
      style: "currency",
      currency: price.currency,
    }).format(price.units / 100);
  } else {
    return Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(price);
  }
}

function isPrice(price: Price | number): price is Price {
  return (price as Price).currency !== undefined;
}
